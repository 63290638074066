

























































import { Api } from '@/services';
import { Skill } from '@/services/skills';
import Vue from 'vue';
type GroupedSkills = Record<'learned' | 'unlearned', Skill[]>;
export default Vue.extend({
  data: () => ({
    listItems: [
      {
        title: 'Development',
        items: [
          'Architected and implemented scalable software solutions',
          'Developed frontend applications for web, mobile, and embedded systems',
          'Designed in-house code libraries to boost efficiency',
          'Led junior developers through code reviews and writing documentation'
        ]
      },
      {
        title: 'Management',
        items: [
          'Managed between 2 to 3 large-scale projects at any given time',
          'Interfaced between external stakeholders and internal developer teams',
          'Planned sprints to allocate resources appropriately and reduce blocks'
        ]
      },
      {
        title: 'Operations',
        items: [
          'Engineered CI/CD pipelines on GitHub and GitLab for many different deployment targets',
          'Drove E2E automation suite using browserstack, appium, and webdriver.io',
          'Managed local development and production environments',
          'Set up observability across multiple types of workloads'
        ]
      }
    ],
    skills: [] as Skill[],
    key: [
      { type: 'language', name: 'Language' },
      { type: 'framework', name: 'Framework' },
      { type: 'technology', name: 'Technology' }
    ] as Skill[],
    loading: false
  }),
  computed: {
    groupedSkills(): GroupedSkills {
      return this.skills.reduce<GroupedSkills>(
        (res, curr) => {
          curr.wantToLearn ? res.unlearned.push(curr) : res.learned.push(curr);
          return res;
        },
        { learned: [], unlearned: [] }
      );
    }
  },
  beforeMount() {
    this.getSkills();
  },
  methods: {
    getSkills() {
      this.loading = true;
      Api.Skills.getSkills()
        .then((res) => (this.skills = res))
        .finally(() => (this.loading = false));
    }
  }
});
