


































import Vue from 'vue';

export default Vue.extend({
  name: 'Home',
  data: () => ({
    roles: [
      'Software Engineer',
      'UI/UX Designer',
      'Systems Architect',
      'Technical Writer',
      'DevOps Engineer',
      'Team Lead',
      'Full-Stack Developer',
      'Project Manager',
      'Database Admin'
    ],
    currentRole: 0
  }),
  computed: {
    containerClass(): Record<string, boolean> {
      const active = this.$vuetify.breakpoint.lgAndUp;
      return {
        'fill-height': active,
        'align-start': active
      };
    }
  },
  mounted() {
    setInterval(() => this.nextRole(), 2000);
  },
  methods: {
    nextRole() {
      if (this.currentRole === this.roles.length - 1) {
        this.currentRole = 0;
      } else {
        this.currentRole++;
      }
    }
  }
});
