







































import Vue from 'vue';
import { Project } from '../services/projects';
import { Api } from '../services';
export default Vue.extend({
  data: () => ({
    projects: [] as Project[],
    loading: false
  }),
  beforeMount() {
    this.getProjects();
  },
  methods: {
    getProjects() {
      this.loading = true;
      Api.Projects.getProjects()
        .then((res) => (this.projects = res))
        .finally(() => (this.loading = false));
    }
  }
});
